unit Unit1;

interface

uses
  System.SysUtils, System.Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, Vcl.Controls, WEBLib.ExtCtrls, WEBLib.WebCtrls,
  Vcl.StdCtrls, WEBLib.StdCtrls, WEBLib.Devices, Data.DB, WEBLib.DB,
  WEBLib.IndexedDb, Vcl.Grids, WEBLib.DBCtrls, WEBLib.Grids;

type
  TForm1 = class(TForm)
    WebFilePicker1: TFilePicker;
    WebImageControl1: TImageControl;
    WebCamera1: TCamera;
    WebButton1: TButton;
    WebDBGrid1: TDBGrid;
    WebIndexedDbClientDataset1: TIndexedDbClientDataset;
    WebDataSource1: TDataSource;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebLeafletMaps1: TLeafletMaps;
    WebGeoLocation1: TGeoLocation;
    btnGetGeo: TButton;
    procedure WebFilePicker1Change(Sender: TObject);
    procedure WebFilePicker1GetFileAsBase64(Sender: TObject;
      AFileIndex: Integer; ABase64: string);
    procedure WebFormCreate(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
    procedure WebDBGrid1Click(Sender: TObject);
    procedure WebGeoLocation1Geolocation(Sender: TObject; Lat, Lon, Alt: Double);
    procedure btnGetGeoClick(Sender: TObject);
  private
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

procedure TForm1.btnGetGeoClick(Sender: TObject);
begin

  WebGeoLocation1.GetGeolocation


end;

procedure TForm1.WebButton1Click(Sender: TObject);
var
  imgbase64: string;
begin

  imgbase64 := WebCamera1.SnapShotAsBase64;
  WebImageControl1.URL := imgbase64;

  WebIndexedDbClientDataset1.Insert;

  WebIndexedDbClientDataset1.FieldByName('name').AsString := 'camera'+inttostr(WebIndexedDbClientDataset1.RecordCount);
  WebIndexedDbClientDataset1.FieldByName('data').AsString := imgbase64;

  WebIndexedDbClientDataset1.Post;

end;

procedure TForm1.WebDBGrid1Click(Sender: TObject);
begin
  WebImageControl1.URL := WebIndexedDbClientDataset1.FieldByName('data').AsString;
end;

procedure TForm1.WebFilePicker1Change(Sender: TObject);
begin
  WebFilePicker1.Files[0].GetFileAsBase64;
end;

procedure TForm1.WebFilePicker1GetFileAsBase64(Sender: TObject;
  AFileIndex: Integer; ABase64: string);
var
  imgbase64: string;
begin
  imgbase64 := 'data:image/png;base64,' + ABase64;

  WebImageControl1.URL := imgbase64;

  WebIndexedDbClientDataset1.Insert;

  WebIndexedDbClientDataset1.FieldByName('name').AsString := WebFilePicker1.Files[0].Name;
  WebIndexedDbClientDataset1.FieldByName('data').AsString := imgbase64;

  WebIndexedDbClientDataset1.Post;

end;

//================================================================================================//

procedure TForm1.WebFormCreate(Sender: TObject);
begin
  WebIndexedDbClientDataset1.IDBDatabaseName := 'pictures';
  WebIndexedDbClientDataset1.IDBObjectStoreName := 'myiddb';
  WebIndexedDbClientDataset1.IDBKeyFieldName := 'id';

  WebIndexedDbClientDataset1.FieldDefs.Add('id', ftInteger, 0, false);
  WebIndexedDbClientDataset1.FieldDefs.Add('name', ftString);
  WebIndexedDbClientDataset1.FieldDefs.Add('data', ftString);

  WebIndexedDbClientDataset1.Active := true;

  WebCamera1.Start;

end;

//================================================================================================//

procedure TForm1.WebGeoLocation1Geolocation(Sender: TObject; Lat, Lon, Alt: Double);
begin

  btnGEtGeo.Caption := FloatToStr(Lat)+' - '+ FloatToStr(Lon);

  WebLeafletMaps1.ClearMarkers;
  WebLeafletMaps1.SetCenter(Lat,Lon);
  WebLeafletMaps1.AddMarker(Lat,Lon,'1','Hier','Pop');

end;

//================================================================================================//

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  WebImageControl1 := TImageControl.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create('title');
  WebLabel6 := TLabel.Create('description');
  WebFilePicker1 := TFilePicker.Create(Self);
  WebCamera1 := TCamera.Create(Self);
  WebButton1 := TButton.Create(Self);
  WebDBGrid1 := TDBGrid.Create(Self);
  WebLeafletMaps1 := TLeafletMaps.Create(Self);
  btnGetGeo := TButton.Create(Self);
  WebIndexedDbClientDataset1 := TIndexedDbClientDataset.Create(Self);
  WebDataSource1 := TDataSource.Create(Self);
  WebGeoLocation1 := TGeoLocation.Create(Self);

  WebImageControl1.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebFilePicker1.BeforeLoadDFMValues;
  WebCamera1.BeforeLoadDFMValues;
  WebButton1.BeforeLoadDFMValues;
  WebDBGrid1.BeforeLoadDFMValues;
  WebLeafletMaps1.BeforeLoadDFMValues;
  btnGetGeo.BeforeLoadDFMValues;
  WebIndexedDbClientDataset1.BeforeLoadDFMValues;
  WebDataSource1.BeforeLoadDFMValues;
  WebGeoLocation1.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 1016;
    Height := 783;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -13;
    Font.Name := 'Tahoma';
    Font.Style := [];
    FormContainer := 'appcontent';
    ParentFont := False;
    TabOrder := 0;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    WebImageControl1.SetParentComponent(Self);
    WebImageControl1.Name := 'WebImageControl1';
    WebImageControl1.Left := 8;
    WebImageControl1.Top := 91;
    WebImageControl1.Width := 305;
    WebImageControl1.Height := 170;
    WebImageControl1.WidthStyle := ssAuto;
    WebImageControl1.HeightPercent := 100.000000000000000000;
    WebImageControl1.WidthPercent := 100.000000000000000000;
    WebImageControl1.ChildOrder := 1;
    WebLabel1.SetParentComponent(Self);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 8;
    WebLabel1.Top := 20;
    WebLabel1.Width := 149;
    WebLabel1.Height := 16;
    WebLabel1.Caption := 'Pick image from local files';
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(Self);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 8;
    WebLabel2.Top := 434;
    WebLabel2.Width := 162;
    WebLabel2.Height := 16;
    WebLabel2.Caption := 'Saved pictures in IndexedDB';
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(Self);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 336;
    WebLabel3.Top := 20;
    WebLabel3.Width := 88;
    WebLabel3.Height := 16;
    WebLabel3.Caption := 'Picture preview';
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(Self);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 8;
    WebLabel4.Top := 69;
    WebLabel4.Width := 139;
    WebLabel4.Height := 16;
    WebLabel4.Caption := 'Pick image from camera';
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(Self);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 32;
    WebLabel5.Top := 600;
    WebLabel5.Width := 186;
    WebLabel5.Height := 16;
    WebLabel5.Caption := 'Local pictures and camera demo';
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(Self);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 32;
    WebLabel6.Top := 632;
    WebLabel6.Width := 182;
    WebLabel6.Height := 16;
    WebLabel6.Caption := 'Using local pictures and camera';
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebFilePicker1.SetParentComponent(Self);
    WebFilePicker1.Name := 'WebFilePicker1';
    WebFilePicker1.Left := 8;
    WebFilePicker1.Top := 39;
    WebFilePicker1.Width := 305;
    WebFilePicker1.Height := 19;
    SetEvent(WebFilePicker1, Self, 'OnChange', 'WebFilePicker1Change');
    SetEvent(WebFilePicker1, Self, 'OnGetFileAsBase64', 'WebFilePicker1GetFileAsBase64');
    WebCamera1.SetParentComponent(Self);
    WebCamera1.Name := 'WebCamera1';
    WebCamera1.Left := 8;
    WebCamera1.Top := 298;
    WebCamera1.Width := 305;
    WebCamera1.Height := 123;
    WebCamera1.CameraType := ctSelected;
    WebButton1.SetParentComponent(Self);
    WebButton1.Name := 'WebButton1';
    WebButton1.Left := 8;
    WebButton1.Top := 267;
    WebButton1.Width := 305;
    WebButton1.Height := 25;
    WebButton1.Caption := 'Take picture with camera';
    WebButton1.ChildOrder := 3;
    WebButton1.HeightPercent := 100.000000000000000000;
    WebButton1.WidthPercent := 100.000000000000000000;
    SetEvent(WebButton1, Self, 'OnClick', 'WebButton1Click');
    WebDBGrid1.SetParentComponent(Self);
    WebDBGrid1.Name := 'WebDBGrid1';
    WebDBGrid1.Left := 8;
    WebDBGrid1.Top := 456;
    WebDBGrid1.Width := 849;
    WebDBGrid1.Height := 120;
    WebDBGrid1.Anchors := [akLeft,akTop,akRight];
    WebDBGrid1.Columns.Clear;
    with WebDBGrid1.Columns.Add do
    begin
    end;
    with WebDBGrid1.Columns.Add do
    begin
      Width := 152;
    end;
    with WebDBGrid1.Columns.Add do
    begin
      Width := 162;
    end;
    with WebDBGrid1.Columns.Add do
    begin
    end;
    WebDBGrid1.DefaultRowHeight := 21;
    WebDBGrid1.DataSource := WebDataSource1;
    WebDBGrid1.FixedFont.Charset := DEFAULT_CHARSET;
    WebDBGrid1.FixedFont.Color := clWindowText;
    WebDBGrid1.FixedFont.Height := -12;
    WebDBGrid1.FixedFont.Name := 'Segoe UI';
    WebDBGrid1.FixedFont.Style := [];
    WebDBGrid1.FixedCols := 1;
    WebDBGrid1.TabOrder := 3;
    WebDBGrid1.HeightPercent := 100.000000000000000000;
    WebDBGrid1.WidthPercent := 100.000000000000000000;
    SetEvent(WebDBGrid1, Self, 'OnClick', 'WebDBGrid1Click');
    WebDBGrid1.ColWidths[0] := 20;
    WebDBGrid1.ColWidths[1] := 64;
    WebDBGrid1.ColWidths[2] := 152;
    WebDBGrid1.ColWidths[3] := 162;
    WebDBGrid1.ColWidths[4] := 64;
    WebDBGrid1.RowHeights[0] := 21;
    WebDBGrid1.RowHeights[1] := 21;
    WebDBGrid1.RowHeights[2] := 21;
    WebDBGrid1.RowHeights[3] := 21;
    WebDBGrid1.RowHeights[4] := 21;
    WebLeafletMaps1.SetParentComponent(Self);
    WebLeafletMaps1.Name := 'WebLeafletMaps1';
    WebLeafletMaps1.Left := 319;
    WebLeafletMaps1.Top := 91;
    WebLeafletMaps1.Width := 538;
    WebLeafletMaps1.Height := 316;
    WebLeafletMaps1.Anchors := [akLeft,akTop,akRight];
    WebLeafletMaps1.ChildOrder := 11;
    WebLeafletMaps1.Options.ZoomControl := lzBottomRight;
    WebLeafletMaps1.Options.DefaultLatitude := -34.397000000000000000;
    WebLeafletMaps1.Options.DefaultLongitude := 150.644000000000000000;
    btnGetGeo.SetParentComponent(Self);
    btnGetGeo.Name := 'btnGetGeo';
    btnGetGeo.Left := 336;
    btnGetGeo.Top := 42;
    btnGetGeo.Width := 201;
    btnGetGeo.Height := 25;
    btnGetGeo.Caption := 'GetGeo...';
    btnGetGeo.ChildOrder := 12;
    btnGetGeo.HeightPercent := 100.000000000000000000;
    btnGetGeo.WidthPercent := 100.000000000000000000;
    SetEvent(btnGetGeo, Self, 'OnClick', 'btnGetGeoClick');
    WebIndexedDbClientDataset1.SetParentComponent(Self);
    WebIndexedDbClientDataset1.Name := 'WebIndexedDbClientDataset1';
    WebIndexedDbClientDataset1.IDBAutoIncrement := True;
    WebIndexedDbClientDataset1.Left := 208;
    WebIndexedDbClientDataset1.Top := 336;
    WebDataSource1.SetParentComponent(Self);
    WebDataSource1.Name := 'WebDataSource1';
    WebDataSource1.DataSet := WebIndexedDbClientDataset1;
    WebDataSource1.Left := 72;
    WebDataSource1.Top := 336;
    WebGeoLocation1.SetParentComponent(Self);
    WebGeoLocation1.Name := 'WebGeoLocation1';
    SetEvent(WebGeoLocation1, Self, 'OnGeolocation', 'WebGeoLocation1Geolocation');
    WebGeoLocation1.Left := 480;
    WebGeoLocation1.Top := 128;
  finally
    WebImageControl1.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebFilePicker1.AfterLoadDFMValues;
    WebCamera1.AfterLoadDFMValues;
    WebButton1.AfterLoadDFMValues;
    WebDBGrid1.AfterLoadDFMValues;
    WebLeafletMaps1.AfterLoadDFMValues;
    btnGetGeo.AfterLoadDFMValues;
    WebIndexedDbClientDataset1.AfterLoadDFMValues;
    WebDataSource1.AfterLoadDFMValues;
    WebGeoLocation1.AfterLoadDFMValues;
  end;
end;

end.
