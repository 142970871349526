{********************************************************************}
{                                                                    }
{ written by TMS Software                                            }
{            copyright (c) 2018 - 2024                               }
{            Email : info@tmssoftware.com                            }
{            Web : http://www.tmssoftware.com                        }
{                                                                    }
{ The source code is given as is. The author is not responsible      }
{ for any possible damage done due to the use of this code.          }
{ The complete source code remains property of the author and may    }
{ not be distributed, published, given or sold in any form as such.  }
{ No parts of the source code can be included in any other component }
{ or application without written authorization of the author.        }
{********************************************************************}

unit WEBLib.CDS;

{$DEFINE NOPP}

interface

uses
  Classes, DB, jsondataset, Web, js, WEBLib.Controls, WEBLib.REST;

type
  TCustomClientDataSet = class;

  TClientDataSource = class(TDataSource)
  end;

  TConnectErrorEvent = procedure(Sender: TObject; ErrorCode: integer) of object;

  TURLType = (utGet,utPost,utPut,utDelete);

  TClientConnectionGetURLEvent = procedure(Sender: TObject; Dataset : TDataset; urlType : TURLType; Var URL : String) of object;

  TGetUpdatePayloadEvent = procedure(Sender : TObject; Dataset : TDataset; urlType : TURLType; Data : JSValue; out aPayLoad : String) of object;

  TGetDataPayloadEvent = procedure(Sender : TObject; Dataset : TDataset; urlType : TURLType; var aPayLoad : JSValue) of object;

  TProcessMetaDataEvent =  procedure(Sender : TObject; Dataset : TDataset; FieldDefs : TFieldDefs; aMetaData : TJSObject) of object;

  TDataReceivedEvent = procedure(Sender: TObject; ARequest: TJSXMLHttpRequestRecord; var AResponse: string) of object;

  // for compatibility with Delphi code
  TWideStringField = class(TStringField);

  TClientConnection = class(TComponent)
  private
    FDataProxy: TDataProxy;
    FActive: boolean;
    FURI: string;
    FDS: TCustomClientDataSet;
    FDataNode: string;
    FAutoOpen: boolean;
    FUpdateCount: integer;
    FOnConnectError: TConnectErrorEvent;
    FBeforeConnect: TNotifyEvent;
    FAfterConnect: TNotifyEvent;
    FHeaders: TStringList;
    FPassword: string;
    FUser: string;
    FOnGetURL: TClientConnectionGetURLEvent;
    FPageParam: string;
    FOnGetUpdatePayLoad: TGetUpdatePayloadEvent;
    FAppendKeyToURL: boolean;
    FOnGetDataPayLoad: TGetDataPayloadEvent;
    FMetaDataNode: string;
    FOnProcessMetaData: TProcessMetadataEvent;
    FOnDataReceived: TDataReceivedEvent;
    FXHR: TJSXMLHttpRequest;
    FCommand: THTTPCommand;
    FCustomCommand: string;
    FPostData: string;
    FDelimiter: char;
    FSkipFirstCSVLine: boolean;
    FOpenResolver: TJSPromiseResolver;
    procedure SetHeaders(const Value: TStringList);
    function GetPageURL(aRequest: TDataRequest): String;
    function GetRecordUpdateURL(aRequest: TRecordUpdateDescriptor): String;
    function GetDataProxy: TDataProxy;
    procedure SetDataNode(const Value: string);
    procedure SetURI(const Value: string);
    function onError(Event: TEventListenerEvent): boolean; virtual;
    function onAbort(Event: TEventListenerEvent): boolean; virtual;
    function onLoad(Event: TEventListenerEvent): boolean; virtual;
  protected
    procedure SetActive(const Value: boolean);
    procedure ProcessMetadata(Dataset : TDataset; FieldDefs : TFieldDefs; aMetaData : TJSObject); virtual;
    function GetReadBaseURL(aRequest: TDataRequest): String; virtual;
    function GetUpdateBaseURL(aRequest: TRecordUpdateDescriptor): String; virtual;
    procedure RegisterDataSet(const value: TCustomClientDataSet);
    procedure DoOpenResolve(AResult: boolean); virtual;
    procedure DoAfterLoad(DataSet: TDataSet);
    procedure DoRequest; virtual;
    procedure DoConnect; virtual;
    procedure DoDisconnect; virtual;
    procedure DoBeforeConnect; virtual;
    procedure DoAfterConnect; virtual;
    procedure DoError(ErrorCode: integer);
    procedure DoDataReceived(ARequest: TJSXMLHttpRequest; var AResponse: string);
    procedure SetupRequest(aXHR: TJSXMLHttpRequest); virtual;
    function GetDataNode : String; virtual;
    function GetMetaDataNode : String; virtual;
    function DoGetDataProxy : TDataProxy; virtual;
    function GetUpdatePayLoad(aURLType : TURLType; aDataset : TDataset; AData : JSValue) : String; virtual;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    function Open: TJSPromise;
    procedure Close;
    procedure AfterLoadDFMValues; override;
    property DataProxy : TDataProxy Read GetDataProxy;
    class function StatusToURLType(aStatus : TUpdateStatus) : TURLType;
  published
    property Active: boolean read FActive write SetActive;
    property AppendKeyToURL : boolean read FAppendKeyToURL write FAppendKeyToURL default true;
    property AutoOpenDataSet: boolean read FAutoOpen write FAutoOpen default true;
    property Command: THTTPCommand read FCommand write FCommand default httpGET;
    property CustomCommand: string read FCustomCommand write FCustomCommand;
    property DataNode: string read FDataNode write SetDataNode;
    property Delimiter: char read FDelimiter write FDelimiter;
    property MetaDataNode: string read FMetaDataNode write FMetaDataNode;
    property PageParam: string read FPageParam write FPageParam;
    property PostData: string read FPostData write FPostData;
    property Headers: TStringList read FHeaders write SetHeaders;
    property Password: string read FPassword write FPassword;
    property SkipFirstCSVLine: boolean read FSkipFirstCSVLine write FSkipFirstCSVLine default false;
    property User: string read FUser write FUser;
    property URI: string read FURI write SetURI;
    property AfterConnect: TNotifyEvent read FAfterConnect write FAfterConnect;
    property BeforeConnect: TNotifyEvent read FBeforeConnect write FBeforeConnect;
   	property OnConnectError: TConnectErrorEvent read FOnConnectError write FOnConnectError;
    property OnDataReceived: TDataReceivedEvent read FOnDataReceived write FOnDataReceived;
    property OnGetURL : TClientConnectionGetURLEvent Read FOnGetURL Write FOnGetURL;
    Property OnGetUpdatePayLoad : TGetUpdatePayloadEvent Read FOnGetUpdatePayLoad Write FOnGetUpdatePayLoad;
    Property OnGetDataPayload : TGetDataPayloadEvent Read FOnGetDataPayLoad Write FOnGetDataPayLoad;
    Property OnProcessMetaData : TProcessMetadataEvent Read FOnProcessMetaData Write FOnProcessMetaData;
  end;

  TWebClientConnection = class(TClientConnection);

  TWebClientDataProxy = class(TDataProxy)
  private
    FConnection: TClientConnection;
  protected
    procedure CheckBatchComplete(aBatch : TRecordUpdateBatch); virtual;
  public
    function GetUpdateDescriptorClass : TRecordUpdateDescriptorClass; override;
    function ProcessUpdateBatch(aBatch : TRecordUpdateBatch): Boolean; override;
    function DoGetData(aRequest: TDataRequest): Boolean; override;
    function GetDataRequest(aOptions: TLoadOptions; aAfterRequest: TDataRequestEvent; aAfterLoad: TDatasetLoadEvent) : TDataRequest; override;
    constructor Create(AOwner: TComponent);  override;
    property Connection: TClientConnection read FConnection;
  end;

  TUpdateRecordEvent = procedure(Sender: TObject; ADescriptor: TRecordUpdateDescriptor) of object;

  TCustomClientDataSet = class(TBaseJSONDataSet)
  private
    FConnection: TClientConnection;
    FOnUpdateRecord: TUpdateRecordEvent;
    FUpdateCount: integer;
    FIDField: string;
    FuseServerMetadata: Boolean;
    FParams: TParams;
    FOpenResolver: TJSPromiseResolver;
    FPostResolver: TJSPromiseResolver;
    FInsertResolver: TJSPromiseResolver;
    FUpdateResolver: TJSPromiseResolver;
    FDeleteResolver: TJSPromiseResolver;
    procedure SetParams(const Value: TParams);
  protected
    function GetStringFieldLength(F: TJSObject; AName: string; AIndex: Integer): integer; virtual;
    function StringToFieldType(S: String): TFieldType; virtual;
    Function DoResolveRecordUpdate(anUpdate: TRecordUpdateDescriptor): Boolean; override;
    procedure ResolveUpdateBatch(Sender: TObject; aBatch: TRecordUpdateBatch); override;
    Function DataPacketReceived(aRequest : TDataRequest) : Boolean; override;
    procedure DoOpenResolve(AResult: boolean); virtual;
    procedure DoPostResolve(AResult: boolean); virtual;
    procedure DoInsertResolve(AResult: boolean); virtual;
    procedure DoUpdateResolve(AResult: boolean); virtual;
    procedure DoDeleteResolve(AResult: boolean); virtual;
    procedure DoUpdateRecord(ADescriptor: TRecordUpdateDescriptor); virtual;
    procedure MetaDataToFieldDefs; override;
    procedure SetConnection(const Value: TClientConnection);
    function CreateFieldMapper : TJSONFieldMapper; override;
    procedure InitFieldDefs; override;
    procedure InternalInitFieldDefs; override;
    procedure FieldDefsFromRows(aRows: TJSArray);
    procedure DoAfterOpen; override;
    procedure DoAfterPost; override;
    procedure DoAfterInsert; override;
    procedure DoAfterDelete; override;
    function DoGetDataProxy: TDataProxy; override;
    property Connection: TClientConnection read FConnection write SetConnection;
    property OnUpdateRecord: TUpdateRecordEvent read FOnUpdateRecord write FOnUpdateRecord;
    property Params: TParams Read FParams Write SetParams;
    property IDField: string read FIDField write FIDField;
    property UseServerMetadata: Boolean Read FuseServerMetadata Write FuseServerMetadata;
    procedure SetActive(Value: Boolean); override;
  public
    constructor Create(AOwner: TComponent); override;
    destructor Destroy; override;
    function OpenAsync: TJSPromise;
    function PostAsync: TJSPromise; virtual;
    function InsertAsync: TJSPromise;
    function AppendAsync: TJSPromise;
    function DeleteAsync: TJSPromise; virtual;
    function ApplyUpdatesAsync: TJSPromise;
    procedure BeginUpdate; override;
    procedure EndUpdate; override;
    procedure AfterLoadDFMValues; override;
    procedure GetChildren(Proc: TGetChildProc; Root: TComponent); override;
    procedure EmptyDataSet;
    property Rows;
    property DataProxy;
    procedure Refresh; reintroduce;
    property Indexes;
    property FieldDefs;
    property ActiveIndex;
    property Active;
  published
  end;

  TClientDataSet = class(TCustomClientDataSet)
  public
    property IDField: string read FIDField write FIDField;
  published
    property Active;
    property Connection;
    property Params;

    property BeforeOpen;
    property AfterOpen;
    property BeforeClose;
    property AfterClose;
    property BeforeInsert;
    property AfterInsert;
    property BeforeEdit;
    property AfterEdit;
    property BeforePost;
    property AfterPost;
    property BeforeCancel;
    property AfterCancel;
    property BeforeDelete;
    property AfterDelete;
    property BeforeScroll;
    property AfterScroll;
    property OnCalcFields;
    property OnDeleteError;
    property OnEditError;
    property OnFilterRecord;
    property OnNewRecord;
    property OnPostError;
    property OnUpdateRecord;
  end;


  TWebClientDataSet = class(TClientDataSet);

implementation

uses
  SysUtils, Types, WEBLib.Dialogs;

type
  { TWebClientDataRequest }

  TWebClientDataRequest = Class(TDataRequest)
  private
    FXHR: TJSXMLHttpRequest;
    FConnection: TClientConnection;
  protected
    function onError(Event: TEventListenerEvent): boolean; virtual;
    function onAbort(Event: TEventListenerEvent): boolean; virtual;
    function onLoad(Event: TEventListenerEvent): boolean; virtual;
    function TransformResult: JSValue; virtual;
    property Connection : TClientConnection read FConnection;
  end;

  { TWebClientUpdateRequest }

  TWebClientUpdateRequest = Class(TRecordUpdateDescriptor)
  private
    FXHR: TJSXMLHttpRequest;
    FBatch: TRecordUpdateBatch;
    FConnection: TClientConnection;
  protected
    function TransformResult: JSValue; virtual;
    function onError(Event: TEventListenerEvent): boolean; virtual;
    function onAbort(Event: TEventListenerEvent): boolean; virtual;
    function onLoad(Event: TEventListenerEvent): boolean; virtual;
    property Connection : TClientConnection read FConnection;
  end;

{ TClientDataSet }

procedure TCustomClientDataSet.AfterLoadDFMValues;
begin
  inherited;

  InitFieldDefs;
end;

function TCustomClientDataSet.AppendAsync: TJSPromise;
begin
  Result := TJSPromise.new(
    procedure(ASuccess, AFailed: TJSPromiseResolver)
    begin
      FInsertResolver := ASuccess;
      Self.Append;
    end)
end;

procedure TCustomClientDataSet.BeginUpdate;
begin
  inherited;
  inc(FUpdateCount);
end;

constructor TCustomClientDataSet.Create(AOwner: TComponent);
begin
  inherited;
  FParams := TParams.Create(TParam);
end;

function TCustomClientDataSet.CreateFieldMapper : TJSONFieldMapper;
begin
  Result := TJSONObjectFieldMapper.Create;
end;

function TCustomClientDataSet.OpenAsync: TJSPromise;
begin
  Result := TJSPromise.new(
    procedure(ASuccess, AFailed: TJSPromiseResolver)
    begin
      FOpenResolver := ASuccess;
      Self.Open;
    end)
end;

function TCustomClientDataSet.PostAsync: TJSPromise;
begin
  Result := TJSPromise.new(
    procedure(ASuccess, AFailed: TJSPromiseResolver)
    begin
      FPostResolver := ASuccess;
      Self.Post;
    end)
end;

function TCustomClientDataSet.DeleteAsync: TJSPromise;
begin
  Result := TJSPromise.new(
    procedure(ASuccess, AFailed: TJSPromiseResolver)
    begin
      FDeleteResolver := ASuccess;
      Self.Delete;
    end)
end;


function TCustomClientDataSet.InsertAsync: TJSPromise;
begin
  Result := TJSPromise.new(
    procedure(ASuccess, AFailed: TJSPromiseResolver)
    begin
      FInsertResolver := ASuccess;
      Self.Insert;
    end)
end;

function TCustomClientDataSet.ApplyUpdatesAsync: TJSPromise;
begin
  Result := TJSPromise.new(
    procedure(ASuccess, AFailed: TJSPromiseResolver)
    begin
      FUpdateResolver := ASuccess;
      Self.ApplyUpdates;
    end)
end;

procedure TCustomClientDataSet.ResolveUpdateBatch(Sender: TObject; aBatch: TRecordUpdateBatch);
var
  i: integer;
  res: boolean;
begin
  inherited;

  res := aBatch.List.Count > 0;

  for i := 0 to aBatch.List.Count - 1 do
  begin
    if Assigned(aBatch.List[i]) and  (aBatch.List[i].ResolveStatus <> rsResolved) then
      res := false;
  end;

  DoUpdateResolve(res);
end;

function TCustomClientDataSet.DoResolveRecordUpdate(anUpdate: TRecordUpdateDescriptor): Boolean;
var
  D: JSValue;
  O: TJSObject;
  A: TJSArray;
  I,RecordIndex: Integer;
  Root,FN,subn: string;

begin
  Result := True;

  if anUpdate.Status = usDeleted then
    Exit;

  D := anUpdate.ServerData;

  If isNull(D) then
    Exit;

  if not isNumber(AnUpdate.Bookmark.Data) then
    Exit(False);

  RecordIndex := Integer(AnUpdate.Bookmark.Data);

  If isString(D) then
    O := TJSOBject(TJSJSON.Parse(String(D)))
  else if isObject(D) then
    O := TJSOBject(D)
  else
    Exit(False);

  Root := Connection.DataNode; // Maybe allow this to be set in the data packet ?

  while Pos('\', root) > 0 do
  begin
    subn := Copy(root, 1, pos('\', root) - 1);
    root := Copy(root, pos('\',root) + 1, Length(root));
    O := TJSObject(O[subn]);
  end;

  if not isArray(O[Root]) then
    Exit(False);

  A := TJSArray(O[Root]);
  if A.Length = 1 then
  begin
    O := TJSObject(A[0]);
    for I := 0 to Fields.Count - 1 do
    begin
      FN := Fields[i].FieldName;
      if O.hasOwnProperty(FN) then
        FieldMapper.SetJSONDataForField(Fields[i],Rows[RecordIndex],O[FN]);
    end;
  end;
end;

function TCustomClientDataSet.DataPacketReceived(aRequest: TDataRequest): Boolean;
var
  O,MD: TJSObject;
  V: JSValue;
  A: TJSArray;
  lmetadata,Root,subn,s: string;
  isjson: boolean;
  sl,lines: TStringList;
  fl,l,c,flds: integer;

begin
  Result := False;
  if isNull(aRequest.Data) then
    Exit;

  O := nil;
  A := nil;

  if isString(aRequest.Data) then
  begin
    isjson := true;
    s := Trim(string(aRequest.Data));
    if (Length(s) > 1) then
    begin
      if (s[1] <> '[') and (s[1] <> '{') then
        isjson := false;
    end;

    if not isjson then
    begin
      sl := TStringList.Create;
      lines := TStringList.Create;
      lines.Delimiter := Connection.Delimiter;
      lines.StrictDelimiter := true;
      sl.Text := string(aRequest.Data);
      A := TJSArray.new;
      A.Length := sl.Count;
      fl := 0;
      if Connection.SkipFirstCSVLine then
        fl := 1;

      for l := fl to sl.Count - 1 do
      begin
        A[l] := TJSArray.new;
        lines.DelimitedText := sl.Strings[l];
        flds := lines.Count;

        o := TJSObject.new;
        for c := 0 to lines.Count - 1 do
        begin
          o.Properties['column'+c.ToString] := lines[c];
        end;

        A[l] := o;
      end;

      Result := (A <> nil) and (A.Length > 0);

      if Assigned(A) and (A.Length > 0) and Assigned(FieldDefs) and (FieldDefs.Count = 0) then
      begin
        for l := 0 to flds - 1 do
          FieldDefs.Add('column'+l.toString, ftString, 255);

        AddToRows(A);
      end;

      lines.Free;
      sl.Free;
      Exit;
    end;
  end;

  // Check the raw result
  if isString(aRequest.Data) then
  begin
    V := TJSJSON.Parse(string(aRequest.Data));
    if isArray(V) then
    begin
      A := TJSArray(V);
    end
    else
      if isObject(V) then
         O := TJSOBject(V);
  end
  else
    if isArray(aRequest.Data) then
    begin
      A := TJSArray(aRequest.Data);
    end
    else if isObject(aRequest.Data) then
    begin
      O := TJSOBject(aRequest.Data);
    end
    else
      DatabaseError('Cannot handle data packet');

  // At this point, either O or A is set.
  // If we have an object, examine it and extract the data
  if Assigned(O) then
  begin
    // Some defaults
    root := Connection.GetDataNode;
    lmetadata := Connection.GetMetaDataNode;

    while Pos('\', root) > 0 do
    begin
      subn := Copy(root, 1, pos('\', root) - 1);
      root := Copy(root, pos('\',root) + 1, Length(root));
      O := TJSObject(O[subn]);
    end;

    if (IDField='') then
      idField := 'id';

    // Check metadata
    if useServerMetadata and O.hasOwnProperty(lMetaData) and isObject(o[lMetaData]) then
    begin
      MD := TJSObject(o[lMetaData]);
      if not Active then // Load fields from metadata
        metaData := MD;
      // We must always check this one...
      if MD.hasOwnProperty('root') and isString(MD['root']) then
        Root := string(metaData['root']);
      if MD.hasOwnProperty('idField') and isString(MD['idField']) then
        IDField := string(MD['idField']);
    end;

    if (Root <> '') and O.hasOwnProperty(Root) and isArray(o[Root]) then
      A := TJSArray(o[Root]);
  end;

  if Assigned(A) and (A.Length > 0) and Assigned(FieldDefs) and (FieldDefs.Count = 0) then
  begin
    FieldDefsFromRows(A);
  end;

  // If we have an array, add to data
  Result := (A <> nil) and (A.Length > 0);

  if Result then
    AddToRows(A);
end;

destructor TCustomClientDataSet.Destroy;
begin
  FreeAndNil(FParams);
  inherited;
end;

function TCustomClientDataSet.DoGetDataProxy: TDataProxy;
begin
  if Assigned(Connection) then
    Result := Connection.DataProxy
  else
    Result := nil;
end;

procedure TCustomClientDataSet.DoInsertResolve(AResult: boolean);
begin
  if Assigned(FInsertResolver) then
    FInsertResolver(AResult);
end;

procedure TCustomClientDataSet.DoUpdateResolve(AResult: boolean);
begin
  if Assigned(FUpdateResolver) then
    FUpdateResolver(AResult);
end;

procedure TCustomClientDataSet.DoOpenResolve(AResult: boolean);
begin
  if Assigned(FOpenResolver) then
  begin
    FOpenResolver(AResult);
    FOpenResolver := nil; // ensure one time call
  end;
end;

procedure TCustomClientDataSet.DoPostResolve(AResult: boolean);
begin
  if Assigned(FPostResolver) then
    FPostResolver(AResult);
end;

procedure TCustomClientDataSet.DoDeleteResolve(AResult: boolean);
begin
  if Assigned(FDeleteResolver) then
    FDeleteResolver(AResult);
end;

procedure TCustomClientDataSet.DoUpdateRecord(ADescriptor: TRecordUpdateDescriptor);
begin
  if Assigned(OnUpdateRecord) then
    OnUpdateRecord(Self, ADescriptor);
end;

procedure TCustomClientDataSet.EmptyDataSet;
begin
  First;
  while not Eof do
    Delete;
end;

procedure TCustomClientDataSet.EndUpdate;
begin
  inherited;
  if FUpdateCount > 0 then
    dec(FUpdateCount);
end;

procedure TCustomClientDataSet.FieldDefsFromRows(aRows : TJSArray);
var
  J: TJSObject;
  JV: JSValue;
  strArr: TStringDynArray;
  i: integer;

begin
  if (FieldDefs.Count = 0) and Assigned(aRows) and (aRows.Length > 0) then
  begin
    J := TJSObject(aRows.Elements[0]);
    strArr := TJSObject.getOwnPropertyNames(J);

    for i := 0 to Length(strArr) - 1 do
    begin
      JV := J.Properties[strArr[i]];

      if isString(JV) then
        FieldDefs.Add(strArr[i],ftString,255);

      if isNumber(JV) then
        FieldDefs.Add(strArr[i],ftFloat);

      if isBoolean(JV) then
        FieldDefs.Add(strArr[i],ftBoolean);

      if isDate(JV) then
        FieldDefs.Add(strArr[i],ftDate);

      if isNull(JV) then
        FieldDefs.Add(strArr[i],ftString,255);
    end;
  end;
end;

procedure TCustomClientDataSet.InitFieldDefs;

  procedure HandleChildField(Child: TComponent);
  begin
    if Child is TStringField then
      FieldDefs.Add((Child as TStringField).FieldName, ftString, (Child as TStringField).Size);

    if Child is TBooleanField then
      FieldDefs.Add((Child as TBooleanField).FieldName, ftBoolean, 0);

    if Child is TAutoIncField then
      FieldDefs.Add((Child as TAutoIncField).FieldName, ftAutoInc, 0)
    else
      if Child is TIntegerField then
        FieldDefs.Add((Child as TIntegerField).FieldName, ftInteger, 0)
    else
      if Child is TLargeIntField then
        FieldDefs.Add((Child as TLargeIntField).FieldName, ftLargeInt, 0)
    else
      if Child is TFloatField then
        FieldDefs.Add((Child as TFloatField).FieldName, ftFloat, 0)
    else
      if Child is TNumericField then
        FieldDefs.Add((Child as TNumericField).FieldName, ftInteger, 0);


    if Child is TDateField then
      FieldDefs.Add((Child as TDateField).FieldName, ftDate, 0)
    else if Child is TTimeField then
      FieldDefs.Add((Child as TTimeField).FieldName, ftDate, 0)
    else if Child is TDateTimeField then
      FieldDefs.Add((Child as TDateTimeField).FieldName, ftDateTime, 0);

    if Child is TMemoField then
      FieldDefs.Add((Child as TMemoField).FieldName, ftMemo, 0)
    else
      if Child is TBlobField then
        FieldDefs.Add((Child as TBlobField).FieldName, ftBlob, 0);
  end;

begin
  FieldDefs.Clear;
  GetChildren(@HandleChildField, Self);
end;

procedure TCustomClientDataSet.InternalInitFieldDefs;
begin
  if (FieldDefs.Count = 0) and Assigned(Rows) and (Rows.Length > 0) then
  begin
    FieldDefsFromRows(Rows);
  end;

  inherited;
end;

procedure TCustomClientDataSet.DoAfterDelete;
begin
  inherited;
  DoDeleteResolve(true);
end;

procedure TCustomClientDataSet.DoAfterInsert;
begin
  inherited;

  DoInsertResolve(true);
end;

procedure TCustomClientDataSet.DoAfterOpen;
begin
  inherited;

  DoOpenResolve(true);

  if Assigned(Connection) and Connection.AutoOpenDataSet then
  begin
    Connection.DoAfterConnect;
  end;
end;

procedure TCustomClientDataSet.DoAfterPost;
begin
  inherited;
  DoPostResolve(true);
end;

procedure TCustomClientDataSet.Refresh;
begin
  if Assigned(Connection) then
    Connection.DoConnect;
end;

procedure TCustomClientDataSet.SetActive(Value: Boolean);
begin
  if (csLoading in ComponentState) then
    Exit;

  if (Fields.Count = 0) and (Assigned(FieldDefs) and (Fielddefs.Count = 0)) then
    Exit;

  inherited;
end;

procedure TCustomClientDataSet.SetConnection(const Value: TClientConnection);
begin
  FConnection := Value;
  if Assigned(Value) then
    Value.RegisterDataSet(Self);
end;

procedure TCustomClientDataSet.SetParams(const Value: TParams);
begin
  FParams.Assign(Value);
end;

function TCustomClientDataSet.StringToFieldType(S: String): TFieldType;
begin
  if (s = 'int') then
    Result := ftInteger
  else if (s = 'bigint') then
    Result := ftLargeInt
  else if (s = 'float') then
    Result := ftFloat
  else if (s = 'bool') then
    Result := ftBoolean
  else if (s = 'date') then
    Result := ftDate
  else if (s = 'datetime') then
    Result := ftDateTime
  else if (s = 'time') then
    Result := ftTime
  else if (s = 'blob') then
    Result := ftBlob
  else if (s = 'string') then
    Result := ftString
  else
    Result := ftString
end;

procedure TCustomClientDataSet.MetaDataToFieldDefs;
var
  A: TJSArray;
  F: TJSObject;
  I,FS: Integer;
  N: string;
  ft: TFieldType;
  D: JSValue;

begin
  FieldDefs.Clear;

  // Allow user to do it.
  Connection.ProcessMetaData(Self,FieldDefs,MetaData);

  if FieldDefs.Count > 0 then
    Exit;

  D := Metadata.Properties['fields'];

  if not IsArray(D) then
    raise EJSONDataset.Create('Invalid metadata object');

  A := TJSArray(D);
  for I := 0 to A.Length - 1 do
  begin
    if not isObject(A[i]) then
      raise EJSONDataset.CreateFmt('Field definition %d in metadata is not an object',[i]);

    F := TJSObject(A[i]);
    D := F.Properties['name'];
    if not isString(D) then
      raise EJSONDataset.CreateFmt('Field definition %d in has no or invalid name property',[i]);

    N := String(D);
    D := F.Properties['type'];

    if IsNull(D) or isUndefined(D) then
      ft := ftstring
    else
      if not isString(D) then
      begin
        raise EJSONDataset.CreateFmt('Field definition %d in has invalid type property',[i])
      end
      else
      begin
        ft := StringToFieldType(String(D));
      end;

    if (ft = ftString) then
      fs := GetStringFieldLength(F,N,I)
    else
      fs := 0;
    FieldDefs.Add(N,ft,fs);
  end;
end;

procedure TCustomClientDataSet.GetChildren(Proc: TGetChildProc;
  Root: TComponent);
var
  i:integer;
begin
  for i := 0 to Fields.Count - 1 do
  begin
    if (Fields[i].Owner = Root) or (Fields[i].DataSet = Root) or (Root = nil) then
    begin
      if Fields[i].Name = '' then
        Fields[i].Name := Name + Fields[i].FieldName;

      Proc(Fields[i]);
    end;
  end;
end;

function TCustomClientDataSet.GetStringFieldLength(F: TJSObject; AName: String; AIndex: Integer): integer;
var
  I,L: Integer;
  D: JSValue;

begin
  Result := 0;
  D := F.Properties['maxLen'];
  if not jsIsNan(toNumber(D)) then
  begin
    Result := Trunc(toNumber(D));
    if (Result <= 0) then
      DatabaseErrorFmt('Invalid maximum length specifier for field %s',[AName],Self)
  end
  else
  begin
    for I := 0 to Rows.Length - 1 do
    begin
      D := FieldMapper.GetJSONDataForField(Aname,AIndex,Rows[i]);
      if isString(D) then
      begin
        l := Length(String(D));
        if L > Result then
          Result := L;
      end;
    end;
  end;

  if (Result = 0) then
    Result := 20;
end;


{ TClientConnection }

procedure TClientConnection.SetActive(const Value: boolean);
begin
  if (FActive <> Value) then
  begin
    FActive := Value;

    if FUpdateCount > 0 then
      Exit;

    if (csLoading in ComponentState) then
      Exit;

    if Value then
    begin
      if (FURI <> '') then
        DoConnect;
    end
    else
      DoDisconnect;
  end;
end;

procedure TClientConnection.SetDataNode(const Value: string);
begin
  if (FDataNode <> Value) and (FUpdateCount = 0) then
    Active := false;

  FDataNode := Value;
end;

procedure TClientConnection.DoConnect;
begin
  if Assigned(FDS) then
  begin
    DoBeforeConnect;
    if FAutoOpen then
    begin
      FDS.Active := false;
      FDS.AfterLoad := DoAfterLoad;
      FDS.Load([], nil);
    end
    else
    begin
      DoRequest;
    end;
  end
  else
    DoRequest;
end;

procedure TClientConnection.DoOpenResolve(AResult: boolean);
begin
  if Assigned(FOpenResolver) then
    FOpenResolver(AResult);
end;

procedure TClientConnection.DoAfterLoad(Dataset: TDataSet);
begin
  DoOpenResolve(True);
end;

procedure TClientConnection.DoRequest;
var
  cmd: string;
begin
  FXHR := TJSXMLHttpRequest.New;
  FXHR.AddEventListener('load', @onLoad);
  FXHR.AddEventListener('abort', @onAbort);
  FXHR.AddEventListener('error', @onError);

  cmd := HTTPCommand(FCommand, FCustomCommand);

  FXHR.open(cmd,URI);
  SetupRequest(FXHR);
  FXHR.setRequestHeader('content-type','application/json');

  if PostData <> '' then
    FXHR.send(PostData)
  else
    FXHR.send();
end;

procedure TClientConnection.DoDataReceived(ARequest: TJSXMLHttpRequest;
  var AResponse: string);
var
  LRequestRec: TJSXMLHttpRequestRecord;
begin
  if Assigned(OnDataReceived) then
  begin
    LRequestRec.req := ARequest;
    OnDataReceived(Self, LRequestRec, AResponse);
  end;
end;

procedure TClientConnection.DoDisconnect;
begin
  if Assigned(FDS) then
  begin
     if FAutoOpen then
       FDS.Active := false;
  end;
end;

procedure TClientConnection.SetHeaders(const Value: TStringList);
begin
  FHeaders.Assign(Value);
end;

procedure TClientConnection.RegisterDataSet(const Value: TCustomClientDataSet);
begin
  FDS := Value;
end;

procedure TClientConnection.AfterLoadDFMValues;
begin
  inherited;

  if FActive and (URI <> '') then
    DoConnect;
end;

procedure TClientConnection.BeginUpdate;
begin
  inherited;
  inc(FUpdateCount);
end;

procedure TClientConnection.Close;
begin
  SetActive(False);
end;

constructor TClientConnection.Create(AOwner: TComponent);
begin
  inherited;
  FActive := false;
  FDS := nil;
  FAutoOpen := true;
  FCommand := httpGET;
  FHeaders := TStringList.Create;
  FDelimiter := ';';
  FSkipFirstCSVLine := false;
  AppendKeyToURL := True;
end;

destructor TClientConnection.Destroy;
begin
  FHeaders.Free;
  inherited;
end;

procedure TClientConnection.DoAfterConnect;
begin
  if Assigned(AfterConnect) then
    AfterConnect(Self);
end;

procedure TClientConnection.DoBeforeConnect;
begin
  if Assigned(BeforeConnect) then
    BeforeConnect(Self);
end;

procedure TClientConnection.DoError(ErrorCode: integer);
begin
  if Assigned(OnConnectError) then
    OnConnectError(Self, ErrorCode);

  DoOpenResolve(False);

  raise Exception.Create('Error connecting to URI ' + FURI);
end;

procedure TClientConnection.EndUpdate;
begin
  inherited;
  if (FUpdateCount > 0) then
    dec(FUpdateCount);
end;

{ TWebClientUpdateRequest }

function TWebClientUpdateRequest.onAbort(Event: TEventListenerEvent): boolean;
begin
  if Assigned(Connection) then
    Connection.DoError(FXHR.Status);

  ResolveFailed(FXHR.StatusText);
  Result := False;
end;

function TWebClientUpdateRequest.onError(Event: TEventListenerEvent): boolean;
begin
  if Assigned(Connection) then
    Connection.DoError(FXHR.Status);
  ResolveFailed(FXHR.StatusText);
  Result := False;
end;

function TWebClientUpdateRequest.onLoad(Event: TEventListenerEvent): boolean;
begin
  if (FXHR.Status div 100) = 2 then
  begin
    Resolve(TransFormResult);
    Result := True;
  end
  else
    ResolveFailed(FXHR.StatusText);

  (Proxy as TWebClientDataProxy).CheckBatchComplete(FBatch);
end;

function TWebClientUpdateRequest.TransformResult: JSValue;
begin
  Result := FXHR.response;
  if Assigned(Connection) and Assigned(Connection.OnGetDataPayload) then
    FConnection.OnGetDataPayload(FConnection,Dataset,TClientConnection.StatusToURLtype(Self.Status),Result);
end;

{ TWebClientDataRequest }

function TWebClientDataRequest.TransformResult: JSValue;
var
  s: string;
begin
  Result := FXHR.response;

  if Assigned(Connection) and Assigned(Connection.OnDataReceived) then
  begin
    asm
      s = this.FXHR.responseText;
    end;
    FConnection.DoDataReceived(FXHR, s);
  end;

  if Assigned(Connection) and Assigned(Connection.OnGetDataPayload) then
  begin
    FConnection.OnGetDataPayload(FConnection,Dataset, utGet, Result);
  end;
end;

function TWebClientDataRequest.onAbort(Event: TEventListenerEvent): boolean;
begin
  Success := rrFail;

  if Assigned(Connection) then
    Connection.DoError(FXHR.Status);

  DoAfterRequest;
  Result := True;
end;

function TWebClientDataRequest.onError(Event: TEventListenerEvent): boolean;
begin
  Success := rrFail;

  if Assigned(Connection) then
    Connection.DoError(FXHR.Status);

  DoAfterRequest;
  Result := True;
end;

function TWebClientDataRequest.onLoad(Event: TEventListenerEvent): boolean;
begin
  if (FXHR.Status = 200) then
  begin
    Data := TransformResult;
    Success := rrOK;
  end
  else
  begin
    Data := nil;
    if (loAtEOF in LoadOptions) and (FXHR.Status = 404) then
      Success := rrEOF
    else
    begin
      Success := rrFail;
      ErrorMsg := FXHR.StatusText;
    end;

    if Assigned(Connection) then
      Connection.DoError(FXHR.Status);
  end;

  DoAfterRequest;
  Result := True;
end;

{ TRESTConnection }

function TClientConnection.GetDataNode: String;
begin
  Result := fDataNode;
  if Result = '' then
    Result := 'data';
end;

function TClientConnection.GetDataProxy: TDataProxy;
begin
  if (FDataProxy=Nil) then
    FDataProxy := DoGetDataProxy;
  Result := FDataProxy;
end;


function TClientConnection.GetMetaDataNode: String;
begin
  Result := fMetaDataNode;
  if Result = '' then
    Result := 'metaData';
end;

procedure TClientConnection.SetupRequest(aXHR: TJSXMLHttpRequest);
var
  I : Integer;
  headname, headvalue : string;

begin
  // always set this, security measure, enforcing use of CORS
  if User <> '' then
    aXHR.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

  for i := 0 to FHeaders.Count - 1 do
  begin
    FHeaders.GetNameValue(i, headname, headvalue);
    aXHR.setRequestHeader(headname, headvalue);
  end;

  // We set the Authorization header here, not in XHR.open.
  // This way we can switch to other forms of authentication (OAuth2).
  if (User<>'') then
    aXHR.setRequestHeader('Authorization', 'Basic '+window.btoa(User+':'+Password));
end;

procedure TClientConnection.SetURI(const Value: string);
begin
  if (FURI <> Value) and (FUpdateCount = 0) then
    Active := false;

  FURI := Value;
end;

class function TClientConnection.StatusToURLType(aStatus : TUpdateStatus): TURLType;
begin
  case aStatus of
    usModified : Result := utPut;
    usDeleted : Result := utDelete;
    usInserted : Result := utPost;
  else
    Result := utGet;
  end;
end;

function TClientConnection.GetReadBaseURL(aRequest: TDataRequest): String;
begin
  Result := URI;
  if Assigned(FOnGetURL) then
    FOnGetURL(Self, aRequest.Dataset,utGet,Result);
end;

function TClientConnection.GetPageURL(aRequest: TDataRequest): String;
var
  URL : String;

begin
  URL := GetReadBaseURL(aRequest);
  if (PageParam <> '') then
  begin
    if Pos('?',URL)<>0 then
      URL := URL + '&'
    else
      URL := URL + '?';
    URL := URL + PageParam + '=' + IntToStr(ARequest.RequestID-1);
  end;
  Result := URL;
end;

function TClientConnection.GetUpdateBaseURL(aRequest: TRecordUpdateDescriptor): String;
begin
  Result := URI;
  if Assigned(FOnGetURL) then
    FOnGetURL(Self,aRequest.Dataset,StatusToURLType(aRequest.Status),Result);
end;

function TClientConnection.GetUpdatePayLoad(aURLType: TURLType; aDataset: TDataset; AData: JSValue): String;
begin
  if Assigned(FOnGetUpdatePayLoad) then
    FOnGetUpdatePayLoad(Self,aDataset,aUrlType,aData,Result)
  else
  begin
    if aURLType in [utPost,utPut] then
      Result := TJSJSON.Stringify(aData)
    else
      Result := '';
  end;
end;

function TClientConnection.onAbort(Event: TEventListenerEvent): boolean;
begin
  if Assigned(OnConnectError) then
  begin
    OnConnectError(Self, FXHR.Status);
  end;
  Result := true;
end;

function TClientConnection.onError(Event: TEventListenerEvent): boolean;
begin
  if Assigned(OnConnectError) then
  begin
    OnConnectError(Self, FXHR.Status);
  end;
  Result := true;
end;

function TClientConnection.onLoad(Event: TEventListenerEvent): boolean;
var
  rec: TJSXMLHttpRequestRecord;
  s: string;
begin
  if Assigned(OnDataReceived) then
  begin
    rec.req := FXHR;
    s := FXHR.responseText;
    OnDataReceived(Self, rec, s);
  end;
  Result := true;
end;

function TClientConnection.Open: TJSPromise;
begin
  Result := TJSPromise.new(
    procedure(ASuccess, AFailed: TJSPromiseResolver)
    begin
      FOpenResolver := ASuccess;

      if (FUpdateCount > 0) or (csLoading in ComponentState) then
        ASuccess(false)
      else
        SetActive(true);
    end
    )
end;

procedure TClientConnection.ProcessMetadata(Dataset: TDataset; FieldDefs: TFieldDefs; aMetaData: TJSObject);
begin
  if Assigned(OnProcessMetaData) then
    OnProcessMetaData(Self,Dataset,FieldDefs,aMetaData);
end;

function TClientConnection.GetRecordUpdateURL(aRequest: TRecordUpdateDescriptor): String;
var
  I: integer;
  Base,KeyField,KeyValue,Qry: string;

begin
  KeyField := '';
  Result := '';
  Base := GetUpdateBaseURL(aRequest);

  if not (AppendKeyToURL and (aRequest.Status in [usModified,usDeleted])) then
    Exit(Base);

  I := Pos('?',Base);
  if I > 0 then
  begin
    Qry := Copy(Base,I,Length(Base)-I+1);
    Base := Copy(Base,1,I-1);
  end;

  // For update & delete we need to append the key field value.
  // Key field is IDField. If that is empty, we look for a field with providerFlags that contains pfKey
  KeyField := (aRequest.Dataset as TClientDataset).IDField;
  I := aRequest.Dataset.Fields.Count - 1;

  while (KeyField = '') and (I >= 0) do
  begin
    if pfInKey in aRequest.Dataset.Fields[i].ProviderFlags then
      KeyField:=aRequest.Dataset.Fields[i].FieldName;
    Dec(I);
  end;

  if (KeyField = '') then
    DatabaseError('No key field',aRequest.Dataset);

  KeyValue := TJSJSON.stringify(TJSObject(aRequest.Data)[KeyField]);
  if (Base<>'') and (Base[Length(Base)]<>'/') then
    Base := Base + '/';

  Result := Base + KeyValue + Qry;
end;

function TClientConnection.DoGetDataProxy: TDataProxy;
begin
  Result := TWebClientDataProxy.Create(Self);
end;

{ TRESTDataProxy }

procedure TWebClientDataProxy.CheckBatchComplete(aBatch: TRecordUpdateBatch);
var
  BatchOK : Boolean;
  I : Integer;

begin
  BatchOK := True;
  I := aBatch.List.Count - 1;
  while BatchOK and (I >= 0) do
  begin
    BatchOK := aBatch.List[I].ResolveStatus in [rsResolved, rsResolveFailed];
    Dec(I);
  end;

  if BatchOK and Assigned(aBatch.OnResolve) then
  begin
    aBatch.OnResolve(Self, aBatch);
  end;
end;

function TWebClientDataProxy.GetUpdateDescriptorClass: TRecordUpdateDescriptorClass;
begin
  Result := TWebClientUpdateRequest;
end;

function TWebClientDataProxy.ProcessUpdateBatch(aBatch: TRecordUpdateBatch): Boolean;

Var
  R : TWebClientUpdateRequest;
  i : Integer;
  Method,URl : String;
  S : String;

begin
  Result := false;
  for I := 0 to aBatch.List.Count - 1 do
  begin
    R := aBatch.List[i] as TWebClientUpdateRequest;
    R.FConnection := Self.FConnection;
    R.FBatch := aBatch;
    R.FXHR := TJSXMLHttpRequest.New;
    R.FXHR.AddEventListener('load',@R.onLoad);
    R.FXHR.AddEventListener('abort',@R.onAbort);
    R.FXHR.AddEventListener('error',@R.onError);
    URL := FConnection.GetRecordUpdateURL(R);

    case R.Status of
    usInserted: Method := 'POST';
    usModified: Method := 'PUT';
    usDeleted: Method := 'DELETE';
    end;
    R.FXHR.open(Method,URL);
    Connection.SetupRequest(R.FXHR);
    R.FXHR.setRequestHeader('content-type','application/json');
    S := Connection.GetUpdatePayload(TClientConnection.StatusToURLType(R.Status),R.Dataset,R.Data);

    if R.Status in [usInserted,usModified] then
      R.FXHR.Send(S)
    else
      R.FXHR.Send(S);
    end;
  Result:=True;
end;

function TWebClientDataProxy.DoGetData(aRequest: TDataRequest): Boolean;
var
  R: TWebClientDataRequest;
  URL,cmd: String;

begin
  Result := False;
  R := aRequest as TWebClientDataRequest;
  R.FXHR := TJSXMLHttpRequest.New;
  URL := Connection.GetPageURL(aRequest);

  if (URL = '') then
  begin
    if loAtEOF in R.LoadOptions then
      R.Success := rrEOF
    else
    begin
      R.Success := rrFail;
      R.ErrorMsg := 'No URL to get data';
      R.DoAfterRequest; // This will free request !
      end;
    end
  else
    begin
      if (loAtEOF in R.LoadOptions) and (Connection.PageParam='') then
        R.Success := rrEOF
    else
    begin
      // We're setting the credentials header ourselves.
      // Firefox does not like it when you set it in open() and manually add the header.
      // In general, the API should be switched to fetch()

      cmd := HTTPCommand(Connection.Command, Connection.CustomCommand);

      R.FXHR.open(cmd, URL, true);
      Connection.SetupRequest(R.FXHR);

//      window.onError:=function(e : TJSErrorEvent) : boolean begin console.log(e) end;
      R.FXHR.AddEventListener('load',@R.onLoad);
      R.FXHR.AddEventListener('abort',@R.onAbort);
      R.FXHR.AddEventListener('error',@R.onError);
      // https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS/Errors/CORSNotSupportingCredentials
//      R.FXHR.withCredentials:=true;

      if Connection.PostData <> '' then
        R.FXHR.send(Connection.PostData)
      else
        R.FXHR.send;
    end;
    Result := True;
  end;
end;

function TWebClientDataProxy.GetDataRequest(aOptions: TLoadOptions; aAfterRequest: TDataRequestEvent; aAfterLoad: TDatasetLoadEvent): TDataRequest;
begin
  Result := TWebClientDataRequest.Create(Self,aOptions, aAfterRequest,aAfterLoad);
  TWebClientDataRequest(Result).FConnection:=Self.FConnection;
end;

constructor TWebClientDataProxy.Create(AOwner: TComponent);
begin
  Inherited;

  If AOwner is TClientConnection then
    FConnection := TClientConnection(aOwner);
end;


end.